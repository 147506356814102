import Mountain1 from "../assets/1.jpg";
import Mountain2 from "../assets/Loop.png";
import Mountain3 from "../assets/bash.jpg";
import Mountain4 from "../assets/php.png";
import DestinationData from "./DestinationData";
import "./DestinationStyles.css";

const Destination = () => {
  return (
    <div className="destination">
      <h1>Popular Demand</h1>
      <p>Web Developer and Software Designer </p>

      <DestinationData 
      className="first-des"
      heading="NodeJS"
      text="Experience RESTful API design and development. 
      Web application development experience using ReactJS. Using MySQL and NoSQL on the back end.
      Integration of third-party services to create robust data analysis and tracking customer behaviors.
      Test Driven Development that prioritizing longevity and error tracking"
      img1={Mountain1}
      img2={Mountain2}
      />

<DestinationData 
      className="first-des-reverse"
      heading="PHP and Bash Scripting"
      text="PHP backends that are afforable and secure. PHP development can speed the process of development.
      Microservices can be created to allow for a robust yet organized code base that integrates well with nodejs.
      Bash scripting allows smooth testing experience. PHP development is facilitated with a eye for architecture
      and resource capabilities.  "
      img1={Mountain3}
      img2={Mountain4}
      />

    </div>
  );
};

export default Destination;
