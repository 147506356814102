import "./AboutUsStyles.css";

function AboutUs() {
  return (
    <div className="about-container">
      <h1>Your Code Here</h1>
    </div>
  );
}

export default AboutUs;
