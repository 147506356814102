import Destination from "../components/Destination";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Trip from "../components/Trip";
import AboutImg from "../assets/Golden-Sky-Boston.jpg";
import Aboutpdf from "../assets/2025ResumeFinal.pdf";


function Home() {
  return (
    <>
    <Navbar/>
    <Hero 
    cName="hero"
    heroImg={AboutImg}
    title="Software Specialist"
    buttonText="Download"
    url={Aboutpdf}
    btnClass="show"
    />
    <Destination />
    <Trip />
    <Footer/>
    
    </>
  );
}

export default Home;
