import React, { useState } from 'react';
import { MDBCardImage, MDBCol, MDBContainer, MDBRow, MDBCardText } from 'mdb-react-ui-kit';
import beijing from '../assets/beijing.jpg';
import india2 from '../assets/india2.JPG';
import italy from '../assets/italy.jpg';
import osaka from '../assets/osaka.jpg';
import Modal from 'react-bootstrap/Modal';
import './ZoomPhoto.css'

export default function RecentPhotos() {
  const [showModal, setShowModal] = useState(false);
  const [clickedImage, setClickedImage] = useState('');

  const handleImageClick = (src) => {
    setClickedImage(src);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setClickedImage('');
  };

  const handleImageZoomOut = () => {
    setShowModal(false);
    setClickedImage('');
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <MDBCardText className="lead fw-normal mb-0">Recent photos</MDBCardText>
      </div>
      <MDBRow>
        <MDBCol className="mb-2">
          <MDBCardImage
            src={beijing}
            alt="image 1"
            className="w-100 rounded-3"
            onClick={() => handleImageClick(beijing)}
          />
        </MDBCol>
        <MDBCol className="mb-2">
          <MDBCardImage
            src={osaka}
            alt="image 1"
            className="w-100 rounded-3"
            onClick={() => handleImageClick(osaka)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol className="mb-2">
          <MDBCardImage
            src={italy}
            alt="image 1"
            className="w-100 rounded-3"
            onClick={() => handleImageClick(italy)}
          />
        </MDBCol>
        <MDBCol className="mb-2">
          <MDBCardImage
            src={india2}
            alt="image 1"
            className="w-100 rounded-3"
            onClick={() => handleImageClick(india2)}
          />
        </MDBCol>
      </MDBRow>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body style={{ padding: 0, overflow: 'hidden' }}>
          <img src={clickedImage} className="w-100" onClick={handleImageZoomOut} />
        </Modal.Body>
      </Modal>
    </>
  );
}
