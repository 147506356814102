import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavbarStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [theme, setTheme] = useState('light');
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // scroll to top of the page
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'light';
    setTheme(savedTheme);
    document.documentElement.setAttribute('data-theme', savedTheme);
  }, []);

  const handleClick = (url) => {
    setClicked(!clicked);
    if (location.pathname === url) {
      setClicked(false);
    }
  };

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
  };

  const MenuItems = [
    {
      title: "Home",
      url: "/",
      cName: "nav-links",
      icon: "fa-solid fa-house-user"
    },
    {
      title: "About",
      url: "/about",
      cName: "nav-links",
      icon: "fa-solid fa-circle-info"
    },
    {
      title: "Contact",
      url: "/contact",
      cName: windowWidth <= 750 ? "nav-links-mobile" : "nav-links",
      icon: "fa-solid fa-address-book"
    }
  ];

  return (
    <nav className="NavbarItems">
      <h1 className="navbar-logo">DG</h1>

      <div className="menu-icons">
        <div className="theme-toggle-switch">
          <input
            type="checkbox"
            id="theme-toggle"
            checked={theme === 'dark'}
            onChange={toggleTheme}
          />
          <label className="slider" htmlFor="theme-toggle">
            <FontAwesomeIcon icon={faSun} className="sun-icon" />
            <div className="slider-track"></div>
            <FontAwesomeIcon icon={faMoon} className="moon-icon" />
          </label>
        </div>

        {windowWidth <= 750 && (
          <button
            className={clicked ? "hamburger hamburger--spin is-active" : "hamburger hamburger--spin"}
            type="button"
            onClick={() => handleClick()}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        )}
      </div>

      <ul className={clicked ? "nav-menu active" : "nav-menu"}>
        {MenuItems.map((item, index) => {
          return (
            <li key={index}>
              <Link
                className={item.cName}
                to={item.url}
                onClick={() => handleClick(item.url)}
              >
                <i className={item.icon}></i>
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Navbar;
