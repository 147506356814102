import React, { useState } from 'react';
import './ProfileStyles.css';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody, MDBCardImage, MDBTypography } from 'mdb-react-ui-kit';
import photo from '../assets/headshot2.png';
import Modal from 'react-bootstrap/Modal';
import './ZoomPhoto.css';
import ZoomPhoto from './ZoomPhoto';
import { height } from '@fortawesome/free-brands-svg-icons/fa42Group';

export default function EditButton() {
  const [theme, setTheme] = useState('light'); // State to track theme
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [clickedImage, setClickedImage] = useState(photo); // State to track the clicked image

  // Function to toggle between light and dark themes
  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  // Function to handle the click event on the profile image
  const handleImageClick = () => {
    setClickedImage(photo);  // Set the clicked image to the profile photo
    setShowModal(true);  // Show the modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
  };

  return (
    <div className={`profile-page ${theme}`}>
      <MDBContainer className="py-5 h-100">
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCol lg="9" xl="10">
            <MDBCard className="profile-card">
              <div className="rounded-top text-white d-flex flex-row profile-header">
                <div className="ms-4 mt-7 d-flex flex-column profile-image">
                  <MDBCardImage
                    src={photo}
                    alt="Profile"
                    className="mt-4 mb-2 img-thumbnail"
                    fluid
                    style={{ width: '200px', height: '200px', zIndex: '1', cursor: 'pointer' }} // Added cursor for interaction
                    onClick={handleImageClick} // Trigger the zoom effect
                  />
                </div>
                <div className="ms-3 profile-details">
                  <MDBTypography tag="h5">Danny Gonzalez</MDBTypography>
                  <MDBCardText>Boston, MA</MDBCardText>
                </div>
              </div>
              {/* <div className="p-4 profile-stats">
                <div className="d-flex justify-content-end text-center py-1">
                  <div>
                    <MDBCardText className="mb-1 h5">253</MDBCardText>
                    <MDBCardText className="small text-muted mb-0">Photos</MDBCardText>
                  </div>
                  <div className="px-3">
                    <MDBCardText className="mb-1 h5">6</MDBCardText>
                    <MDBCardText className="small text-muted mb-0">Yrs Exp</MDBCardText>
                  </div>
                  <div>
                    <MDBCardText className="mb-1 h5">4</MDBCardText>
                    <MDBCardText className="small text-muted mb-0">Companies</MDBCardText>
                  </div>
                </div>
              </div> */}
              <MDBCardBody className="text-black p-4 profile-content">
                <div className="mb-5">
                  <div style={{height: '40px'}}></div>
                  <h2 className="heading">Granite Telecommunications MS Representative</h2>
                  <div className="p-4 profile-experience">
                    <ul className="custom-list">
                      <li className="item mb-4">
                        <div className="section-title">Cisco Networking</div>
                        <ul>
                          <li>Extensive experience configuring, troubleshooting, and monitoring Cisco routers and switches to ensure optimal performance and reliability in critical business environments.</li>
                          <li>Proficient in Cisco Viptela SD-WAN, managing and troubleshooting complex, distributed network infrastructures for seamless connectivity across thousands of sites.</li>
                        </ul>
                      </li>
                      <li className="item mb-4">
                        <div className="section-title">Fortinet Security</div>
                        <ul>
                          <li>Expertise in deploying, managing, and troubleshooting Fortinet firewalls, implementing robust security protocols tailored to diverse client needs.</li>
                          <li>Skilled at setting up and managing Flex-Edge routers with Fortinet integration, ensuring secure and scalable network solutions for multi-site operations.</li>
                        </ul>
                      </li>
                      <li className="item mb-4">
                        <div className="section-title">Juniper Networks & Mist AI</div>
                        <ul>
                          <li>Proficient in using the Mist MSP cloud portal to centrally manage and monitor Juniper-based network infrastructures, including wireless, switching, and security systems.</li>
                          <li>Experienced in leveraging AI-driven insights for troubleshooting network issues and optimizing performance as customer needs evolve.</li>
                        </ul>
                      </li>
                      <li className="item mb-4">
                        <div className="section-title">Meraki Wireless Expertise</div>
                        <ul>
                          <li>Hands-on experience deploying, configuring, monitoring, and troubleshooting Meraki wireless networks, providing secure and reliable connectivity across distributed locations.</li>
                        </ul>
                      </li>
                      <li className="item mb-4">
                        <div className="section-title">Connectivity Expertise</div>
                        <ul>
                          <li>Extensive knowledge of MPLS, DIA, and Broadband solutions, with a strong track record of troubleshooting and optimizing these services during router and firewall deployments.</li>
                        </ul>
                      </li>
                      <li className="item">
                        <div className="section-title">High-Availability Deployments</div>
                        <ul>
                          <li>Proven ability to design, deploy, and troubleshoot HA routers and failover systems, ensuring uninterrupted operations for business-critical networks.</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <ZoomPhoto />
              </MDBCardBody>




            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      {/* Modal to show the zoomed-in image */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body style={{ padding: 0, overflow: 'hidden' }}>
          <img src={clickedImage} className="w-100" onClick={handleCloseModal} alt="Zoomed profile" />
        </Modal.Body>
      </Modal>
    </div>
  );
}
